<template>
  <div>
    <ToolbarAdmin />
    <AdminBulletin />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ToolbarAdmin from '@/components/admin/ToolbarAdmin.vue'
import AdminBulletin from '@/components/admin/AdminBulletin.vue'

export default {
  components: {
    ToolbarAdmin,
    AdminBulletin
  },
  computed: {
    ...mapGetters('app', {
      isAuthenticated: 'isAuthenticated',
      canUploadBulletin: 'canUploadBulletin'
    })
  },
  mounted() {
    if (!this.isAuthenticated || !this.canUploadBulletin) {
      this.$router.push('/')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Bulletin'
      }
    }
  }
}
</script>