<template>
  <v-sheet>
    <v-container fluid>
      <v-row justify="space-around">
        <v-col cols="12">
          <v-container fluid>
            <v-row align="center">
              <v-col
                class="d-flex"
                cols="12"
                sm="8"
              >
                <h3>
                  Manage Bulletin
                </h3>
              </v-col>
            </v-row>

            <div class="text-center">            
              <v-overlay :value="loading">
                <v-progress-circular
                  indeterminate
                  size="64"
                ></v-progress-circular>
              </v-overlay>
              
              <v-form ref="form" v-model="isValid">
                <v-card>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedBulletin.title"
                            label="Title"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-btn
                            class="ma-2"
                            outlined
                            color="indigo"
                            target="_blank"
                            :href="editedBulletin.linkUrl"
                          >
                            Current Bulletin
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-file-input
                            v-model="bulletinFile"
                            :rules="[rules.size]"
                            show-size
                            accept="application/pdf"
                            prepend-icon="mdi-file"
                            label="Church Bulletin File"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="!isValid"
                      @click="save()"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </div>
          </v-container></v-col>
      </v-row>
    </v-container>
  
  </v-sheet>
</template>

<script>

import axios from 'axios'

export default {
  data () {
    return {
      loading: true,
      errored: false,
      editedBulletin: {},
      bulletinFile: null,
      rules: {
        size: (value) => !value || value.size < 15000000 || 'File size should be less than 15 MB!',
        required: (value) => {
          return !!value || 'File is required!'
        }
      },
      isValid: true
    }
  },
  async mounted () {
    await this.loadBulletin()
  },
  methods: {
    async save() {
      this.loading = true
      const headers = await this.getHeaders()

      this.$set(headers, 'Content-Type', 'multipart/form-data')

      const formData = new FormData()

      if (this.bulletinFile) {
        formData.append('file', this.bulletinFile, this.bulletinFile.name )
      }

      formData.append('Target', '_blank')
      formData.append('Title', this.editedBulletin.title)
      formData.append('LinkUrl', this.editedBulletin.linkUrl)

      axios({
        method: 'post',
        url: this.getBulletinApiUrl(),
        headers: headers,
        data: formData
      })
        .then(async (response) => {          
          console.log(response)
          this.editedBulletin.linkUrl = response.data.linkUrl
          this.bulletinFile = null
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    async getHeaders() {
      const accessToken = await this.$msal.acquireToken()

      return {
        Authorization: 'Bearer ' + accessToken,
        'Ocp-Apim-Subscription-Key': process.env.VUE_APP_BANNERS_API_KEY
      }
    },
    getBulletinApiUrl() {
      return `${process.env.VUE_APP_BANNERS_API_URL}bulletin/${process.env.VUE_APP_BANNERS_API_ENTITY}`
    },
    async loadBulletin() {
      axios ({
        method: 'get',
        url: this.getBulletinApiUrl(),
        headers: await this.getHeaders()
      })
        .then((response) => {
          this.editedBulletin = response.data[0].bulletin
        })
        .catch((error) => {
          console.log(error)
          this.errored = true
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>